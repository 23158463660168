<template>
  <div class="main">
    <div class="modal-container" v-if="isOpen">
      <div>
        <button class="close" @click="handleClose">
          <img src="/images/professionals/icons/close.svg" alt="" />
        </button>
        <img
          src="/images/professionals/instagram.svg"
          class="instagram-logo"
          alt=""
        />
        <p class="title">
          Conecte seu Insta e deixe seu perfil ainda mais atrativo
        </p>
        <p class="description">
          Tenha dados de seguidores, média de likes, views e comentários, torne
          seu perfil mais atrativo e feche um trabalho ainda mais rápido
        </p>
        <img src="/images/professionals/ticket.png" class="ticket" alt="" />
        <img
          src="/images/professionals/ticket-mobile.png"
          class="mobile-ticket"
          alt=""
        />
        <button class="connect-btn" @click="handleAuthorize">
          <img src="/images/professionals/icons/instagram.svg" alt="" />
          <span>Conectar Instagram</span>
        </button>
      </div>
    </div>
    <div class="header">
      <img src="/images/professionals/profile-bg.png" />
      <div class="header-content">
        <button class="edit-btn">
          <img src="/images/professionals/icons/edit.svg" alt="" />
        </button>
        <div>
          <div class="avatar">
            <img src="/images/professionals/avatar.png" alt="" />
            <button class="edit-btn">
              <img src="/images/professionals/icons/edit.svg" alt="" />
            </button>
          </div>
        </div>
        <div>
          <div>
            <div class="name">
              <p>João Almeida</p>
              <p>
                São Paulo, SP
                <button class="edit-btn">
                  <img src="/images/professionals/icons/edit.svg" alt="" />
                </button>
              </p>
            </div>
            <div class="rate">
              <Rating :rate="4.3" />
            </div>
            <div class="tags">
              <span>Webdesigner</span>
              <span>Branding</span>
              <span>UX design</span>
              <button class="edit-btn">
                <img src="/images/professionals/icons/edit.svg" alt="" />
              </button>
            </div>
            <div class="detail" @click="handleToggleDetail">
              <span>Mais detalhes</span>
              <img
                src="/images/professionals/icons/arrow-down-white.svg"
                alt=""
                :class="{ rotate180: isDetail && isMobile }"
              />
            </div>
            <button
              class="hire-btn"
              v-if="!isMobile || (isMobile && !isDetail)"
            >
              Contratar
            </button>
            <ul class="tab" v-if="authorized">
              <li
                v-for="tab in tabs"
                :key="tab.id"
                :class="{ active: tab.id === selectedTab }"
                @click="handleSelectTab(tab.id)"
              >
                {{ tab.name }}
              </li>
            </ul>
          </div>
          <div v-if="!isMobile || (isMobile && isDetail)">
            <p>
              Designer especialista em UX. Realizei projetos para clientes como
              Google, Coca-Cola, 99 e outros. Apaixonado por viagens e Pets.
              <button class="edit-btn">
                <img src="/images/professionals/icons/edit.svg" alt="" />
              </button>
            </p>
            <div class="social-btns">
              <button>
                <img src="/images/professionals/icons/behance.svg" alt="" />
              </button>
              <button>
                <img src="/images/professionals/icons/linkedin.svg" alt="" />
              </button>
              <button>
                <img src="/images/professionals/icons/facebook.svg" alt="" />
              </button>
              <button>
                <img src="/images/professionals/icons/twitter.svg" alt="" />
              </button>
            </div>
            <div class="info">
              <p>
                rodrigoalmeida.com.br
                <button class="edit-btn">
                  <img src="/images/professionals/icons/edit.svg" alt="" />
                </button>
              </p>
              <p>creators.llc/1385/joao</p>
              <p>Creators desde 05/07/2018</p>
            </div>
            <button class="hire-btn">Contratar</button>
          </div>
        </div>
      </div>
      <div class="connect-instagram" v-if="!authorized">
        <ConnectInstagramWidget :handleOpen="handleOpen" />
      </div>
    </div>
    <div class="body" v-if="authorized">
      <div>
        <div class="info">
          <Info />
        </div>
        <div
          class="overview"
          v-if="!isMobile || (isMobile && selectedTab === 'number')"
        >
          <Overview :data="this.overviewInfo" />
        </div>
      </div>
      <div
        class="cards"
        v-if="!isMobile || (isMobile && selectedTab === 'post')"
      >
        <Card
          :sliderImgs="[
            '/images/professionals/slider/1.png',
            '/images/professionals/slider/1.png',
            '/images/professionals/slider/1.png',
          ]"
        />
        <Card
          :sliderImgs="[
            '/images/professionals/slider/2.png',
            '/images/professionals/slider/2.png',
            '/images/professionals/slider/2.png',
          ]"
        />
        <Card
          :sliderImgs="[
            '/images/professionals/slider/3.png',
            '/images/professionals/slider/3.png',
            '/images/professionals/slider/3.png',
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Card from "./Card.vue";
import ConnectInstagramWidget from "./ConnectInstagramWidget.vue";
import Info from "./Info.vue";
import Overview from "./Overview.vue";
import Rating from "./Rating.vue";

export default {
  components: {
    Card,
    ConnectInstagramWidget,
    Info,
    Overview,
    Rating,
  },
  data: function () {
    return {
      isDetail: false,
      tabs: [
        {
          id: "post",
          name: "Últimos posts",
        },
        {
          id: "number",
          name: "Números",
        },
      ],
      selectedTab: "post",
      isMobile: false,
      isOpen: false,
      authorized: false,
      overviewInfo: [
        {
          id: "followers",
          value: "1.375.446",
          name: "Followers",
          icon: "/images/professionals/icons/users.svg",
        },
        {
          id: "link",
          value: "375.446",
          name: "Média de likes",
          icon: "/images/professionals/icons/thumbs-up.svg",
        },
        {
          id: "views",
          value: "11.375.446",
          name: "Média de views",
          icon: "/images/professionals/icons/eye-password.svg",
        },
        {
          id: "comments",
          value: "446",
          name: "Média de comentários",
          icon: "/images/professionals/icons/chat-baloon.svg",
        },
      ],
    };
  },
  methods: {
    handleOpen: function () {
      this.isOpen = true;
    },
    handleClose: function () {
      this.isOpen = false;
    },
    handleAuthorize: function () {
      this.authorized = true;
      this.isOpen = false;
    },
    handleSelectTab: function (tab) {
      this.selectedTab = tab;
    },
    handleToggleDetail: function () {
      this.isDetail = !this.isDetail;
    },
  },
  mounted: function () {
    this.isMobile = window.innerWidth < 1024;
    window.addEventListener("resize", function () {
      this.isMobile = window.innerWidth < 1024;
    });
  },
};
</script>

<style scoped lang="scss">
.main {
  .rotate180 {
    transform: rotate(180deg);
  }
  .modal-container {
    background: #00000070;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;

    & > div {
      margin-top: 176px;
      background: #ffffff;
      border-radius: 16px;
      width: 854px;
      height: max-content;
      padding: 72px 90px 40px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .instagram-logo {
        widows: 40px;
        height: 40px;
      }

      button.close {
        position: absolute;
        top: 32px;
        right: 32px;
      }

      .instagram-logo {
        margin-bottom: 20px;
      }

      .title {
        max-width: 518px;
        font-family: SF Pro Display;
        font-weight: bold;
        font-size: 32px;
        line-height: 38px;
        color: #000000;
        text-align: center;
        margin-bottom: 16px;
      }

      .description {
        max-width: 458px;
        font-family: SF Pro Display;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        color: #000000;
        margin-bottom: 32px;
      }

      .mobile-ticket {
        display: none;
        width: 100%;
        max-width: fit-content;
      }

      button.connect-btn {
        background: #7553e2;
        border-radius: 10px;
        height: 48px;
        padding: 0 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;

        span {
          font-family: SF Pro Display;
          font-weight: bold;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0.5px;
          color: #ffffff;
          margin-left: 8px;
        }
      }
    }
  }

  .edit-btn {
    width: 24px !important;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000060;
    border: none;
  }

  .header {
    position: relative;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .header-content {
      min-height: 672px;
      padding: 70px 113px;
      width: 100%;
      position: relative;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.4) 30.73%,
          rgba(94, 94, 94, 0.13) 59.57%,
          rgba(57, 57, 57, 0.717295) 81.12%,
          #000000 95.97%
        );
      background-blend-mode: normal, darken;

      & > .edit-btn {
        top: 28px;
        right: 120px;
      }

      & > div {
        margin-bottom: 30px;
        display: flex;

        .avatar {
          position: relative;

          & > img {
            width: 114px;
            height: 114px;
            border-radius: 50%;
          }

          button {
            position: absolute;
            bottom: -2px;
            right: -12px;
          }
        }

        &:last-child {
          display: flex;
          width: 100%;

          & > div {
            width: 50%;
            &:first-child {
              .name {
                margin-bottom: 32px;

                p {
                  font-family: SF Pro Display;
                  font-weight: bold;
                  color: #ffffff;
                  position: relative;

                  &:first-child {
                    font-size: 46px;
                    line-height: 55px;
                    margin-bottom: 12.5px;
                  }

                  &:last-child {
                    display: flex;
                    align-items: center;
                    font-style: normal;
                    font-size: 16px;
                    line-height: 20px;

                    button {
                      position: relative !important;
                      top: auto;
                      left: 8px;
                      right: auto;
                      bottom: auto;
                    }
                  }
                }
              }

              .rate {
                margin-bottom: 30px;
              }

              .tags {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-bottom: 54px;
                position: relative;

                span {
                  display: flex;
                  align-items: center;
                  background: rgba(255, 255, 255, 0.3);
                  border-radius: 24px;
                  height: 26px;
                  font-family: SF Pro Display;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 16px;
                  color: #ffffff;
                  padding: 0 12px;
                  margin-right: 12px;

                  &:last-child {
                    margin-right: 0;
                  }
                }

                button {
                  position: relative;
                  right: auto;
                  bottom: auto;
                }
              }

              .detail {
                cursor: pointer;
                display: none;
                justify-content: center;
                align-items: center;
                font-family: SF Pro Display;
                font-weight: bold;
                font-size: 15px;
                line-height: 20px;
                color: #ffffff;
                margin-bottom: 46px;

                img {
                  margin-left: 4px;
                }
              }

              .hire-btn {
                width: 100%;
                max-width: 360px;
                height: 48px;
                background: #7553e2;
                border-radius: 10px;
                font-family: SF Pro Display;
                font-weight: bold;
                font-size: 16px;
                line-height: 16px;
                color: #ffffff;
                border: none;
                margin-bottom: 48px;
              }

              .tab {
                display: none;
                align-items: center;
                margin: 0;
                padding: 0;

                li {
                  margin: 0 8px;
                  list-style: none;
                  font-family: SF Pro Display;
                  font-weight: bold;
                  font-size: 18px;
                  line-height: 24px;
                  color: #ffffff;
                  cursor: pointer;
                  opacity: 0.4;

                  &.active {
                    text-decoration: underline;
                    text-decoration-thickness: 2px;
                    opacity: 1;
                  }
                }
              }
            }

            &:last-child {
              max-width: 438px;
              & > p {
                position: relative;
                font-family: SF Pro Display;
                font-weight: bold;
                font-size: 18px;
                line-height: 28px;
                color: #ffffff;
                border-radius: 5px;
                margin-bottom: 36px;

                button {
                  display: inline-flex !important;
                  position: relative;
                  bottom: auto;
                  right: auto;
                }
              }

              .hire-btn {
                display: none;
                width: 100%;
                max-width: 360px;
                height: 48px;
                background: #7553e2;
                border-radius: 10px;
                font-family: SF Pro Display;
                font-weight: bold;
                font-size: 16px;
                line-height: 16px;
                color: #ffffff;
                border: none;
                margin-bottom: 48px;
              }

              .social-btns {
                display: flex;
                margin-bottom: 42px;

                button {
                  width: 48px;
                  height: 48px;
                  background: #7f7f7f;
                  border-radius: 24px;
                  margin-right: 16px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: none;
                }
              }

              .info {
                p {
                  font-family: SF Pro Display;
                  font-weight: bold;
                  font-size: 15px;
                  line-height: 20px;
                  color: #ffffff;
                  margin-bottom: 10px;

                  button {
                    position: relative;
                    margin-left: 4px;
                    bottom: auto;
                    right: auto;
                    display: inline-flex !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    .connect-instagram {
      position: absolute;
      left: 110px;
      transform: translate(0, -63px);
      z-index: 1;
    }
  }

  .body {
    padding: 0 110px;

    & > div {
      &:first-child {
        margin-top: -64px;
        position: relative;
        z-index: 1;
        display: flex;
        margin-bottom: 40px;

        .info {
          width: 100%;
          max-width: 360px;
          flex-shrink: 0;
          margin-right: 56px;
        }

        .overview {
          width: 100%;
        }
      }
    }

    .cards {
      margin: 0 -28px;
      display: flex;
      flex-wrap: wrap;
      & > div {
        margin-left: 28px;
        margin-right: 28px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .main {
    .modal-container {
      padding: 16px;
      & > div {
        margin-top: 0 !important;
        width: 100%;
        padding: 40px 16px 16px;

        .close {
          top: 16px !important;
          right: 16px !important;
        }

        .title {
          font-size: 20px !important;
          line-height: 24px !important;
        }

        .ticket {
          display: none;
        }

        .mobile-ticket {
          display: inline;
        }

        .description {
          font-size: 15px !important;
          line-height: 24px !important;
          margin-bottom: 16px !important;
        }
      }
    }

    .edit-btn {
      display: none !important;
    }

    .header {
      .header-content {
        padding: 40px 20px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 600px !important;

        & > div {
          &:nth-child(2) {
            margin-bottom: 24px !important;
          }
          &:last-child {
            flex-direction: column;
            align-items: center;

            & > div {
              width: 100%;
              &:first-child {
                display: flex;
                flex-direction: column;
                align-items: center;

                .name {
                  margin-bottom: 16px !important;
                  & > p {
                    text-align: center;

                    &:first-child {
                      font-size: 24px !important;
                      line-height: 24px !important;
                      margin-bottom: 8px !important;
                    }

                    &:last-child {
                      justify-content: center;
                      font-size: 13px !important;
                      line-height: 22px !important;
                    }
                  }
                }

                .tab {
                  display: flex !important;
                }

                .rate {
                  display: flex;
                  justify-content: center;
                }

                .tags {
                  display: none;
                }

                .detail {
                  display: flex !important;
                }
              }

              &:last-child {
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .edit-btn {
                  display: none !important;
                }

                & > p {
                  &:first-child {
                    text-align: center;
                    font-size: 16px !important;
                    line-height: 20px !important;
                    margin-bottom: 20px !important;
                  }
                }

                .hire-btn {
                  display: inline !important;
                }

                .social-btns {
                  margin-bottom: 20px !important;
                }

                .info {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  margin-bottom: 30px;

                  p {
                    font-size: 12px !important;
                    line-height: 12px !important;
                  }
                }
              }
            }
          }
        }
      }

      .connect-instagram {
        display: flex;
        justify-content: center;
        left: 16px;
        right: 16px;
        transform: translate(0, -70px);
      }
    }

    .body {
      padding: 16px;

      & > div {
        &:first-child {
          margin-top: -70px;
          flex-direction: column;
          align-items: center;
          margin-bottom: 0 !important;

          .info {
            margin-right: 0;
            margin-bottom: 8px;
          }

          .overview {
            display: flex;
            justify-content: center;
          }
        }
      }

      .cards {
        margin: 0;
        flex-direction: column;
        align-items: center;
        & > div {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}
</style>