<template>
  <div class="main">
    <div class="header">
      <div>
        <img
          src="/images/professionals/profile/avatar.png"
          class="avatar"
          alt=""
        />
        <div>
          <span>Arneo Paris</span>
          <span>Arneo</span>
        </div>
      </div>
      <button>
        <img src="/images/professionals/icons/3dots.svg" alt="" />
      </button>
    </div>
    <div class="body custom-slider">
      <Carousel :per-page="1" :navigate-to="someLocalProperty">
        <Slide v-for="(slider, index) in this.sliderImgs" :key="index">
          <img :src="slider" alt="" />
        </Slide>
      </Carousel>
      <div>
        <span>En savoir plus</span>
        <img src="/images/professionals/icons/arrow-right.svg" alt="" />
      </div>
    </div>
    <div class="footer">
      <div class="toolbar">
        <div>
          <img src="/images/professionals/icons/heart.svg" alt="" />
          <img src="/images/professionals/icons/comment.svg" alt="" />
          <img src="/images/professionals/icons/share.svg" alt="" />
        </div>
        <img src="/images/professionals/icons/bookmark.svg" alt="" />
      </div>
      <div>
        <p>Aimé par Gabdu et d’autres personnes</p>
        <p>
          ArthurHazan Quel plaisir de retrouver mes étudiants de Web 2 ! Ils ont
          tellement progressés depuis l’année dernière !
          <a href="#"> #Proud </a>
        </p>
        <a href="#">Voir les 10 commentaires</a>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },
  props: {
    sliderImgs: Array,
  },
};
</script>

<style lang="scss">
.custom-slider {
  .VueCarousel-dot-container {
    margin-top: 65px !important;
  }
  .VueCarousel-dot {
    width: 6px !important;
    height: 6px !important;
    border-radius: 50%;
    margin: 0 2px;
    margin-top: 0 !important;
    padding: 0 !important;
    background: #c4c4c4 !important;
  }

  .VueCarousel-dot--active {
    background: #0098fd !important;
  }
}
</style>

<style scoped lang="scss">
.main {
  max-width: 360px;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 16px;

  .header {
    height: 40px;
    padding: 2px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;

      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 10px;
      }

      div {
        display: flex;
        flex-direction: column;

        span {
          font-family: SF Pro Display;
          font-size: 12px;
          line-height: 12px;
          letter-spacing: -0.165px;
          color: #000000;

          &:last-child {
            font-size: 10px;
            line-height: 11px;
          }
        }
      }
    }

    button {
      padding: 0;
      background: none;
      border: none;
    }
  }

  .body {
    & > div {
      &:last-child {
        margin-top: -88px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #20435b;
        padding: 0 15px;

        span {
          font-family: SF Pro Display;
          font-size: 14px;
          line-height: 15px;
          letter-spacing: -0.165px;
          color: #ffffff;
        }
      }
    }
  }

  .footer {
    padding: 2px 8px;

    & > div {
      &.toolbar {
        padding: 12px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          img {
            margin-right: 16px;
          }
        }
      }
      &:last-child {
        p {
          font-family: SF Pro Display;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.165px;
          color: #000000;
          margin-bottom: 6px;

          &:nth-child(2) {
            padding: 0 20px;

            a {
              color: #0098fd;
            }
          }
        }

        & > a {
          font-family: SF Pro Display;
          font-size: 11px;
          line-height: 14px;
          letter-spacing: -0.165px;
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
}
</style>