<template>
  <div>
    <img
      src="/images/professionals/icons/star-full.svg"
      v-for="i in Math.floor(this.rate)"
      :key="i"
      alt=""
    />
    <img
      src="/images/professionals/icons/star-half.svg"
      v-for="i in 5 - Math.floor(this.rate)"
      :key="i"
      alt=""
    />
    <span>{{ this.rate.toString().split(".").join(",") }}</span>
  </div>
</template>

<script>
export default {
  props: {
    rate: Number,
  },
};
</script>

<style scoped lang="scss">
div {
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
  }

  span {
    margin-left: 4px;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }
}
</style>