<template>
  <div class="main">
    <p>
      Conecte seu perfil do Instagram para obter dados de sua conta e deixar seu
      perfil ainda mais atrativo.
      <a href="#"> Veja como funciona </a>
    </p>
    <button @click="handleOpen">
      <img src="/images/professionals/icons/instagram.svg" alt="" />
      <span>Conectar Instagram</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    handleOpen: Function,
  },
};
</script>

<style scoped lang="scss">
.main {
  background: #1e1e1e;
  border-radius: 10px;
  max-width: 360px;
  padding: 35px 32px 32px;

  p {
    font-family: SF Pro Display;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 30px;

    a {
      text-decoration: underline;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #7553e2;
    border-radius: 10px;
    height: 48px;
    border: none;

    span {
      margin-left: 4px;
      font-family: SF Pro Display;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      color: #ffffff;
    }
  }
}
</style>