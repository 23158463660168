<template>
  <div class="main">
    <div class="select" v-click-outside="handleHide">
      <div class="selected-value" @click="handleToggle">
        <div class="item">
          <img :src="selectedType.icon" alt=":( Not Found" />
          <span>{{ selectedType.name }}</span>
        </div>
        <div class="arrow">
          <img
            v-if="!isOpen"
            src="/images/professionals/icons/arrow-down.svg"
            alt=""
          />
          <img v-else src="/images/professionals/icons/arrow-up.svg" alt="" />
        </div>
      </div>
      <ul v-if="isOpen">
        <li
          v-for="item in this.types.filter((type) => type !== selectedType)"
          :key="item.id"
        >
          <div class="item" @click="handleSelectType(item)">
            <img :src="item.icon" alt=":( Not Found" />
            <span>{{ item.name }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="profile">
      <img src="/images/professionals/avatar.png" alt="" />
      <div>
        <span>Nome da conta</span>
        <span>John :3 Kpoper</span>
      </div>
    </div>
  </div>
</template>
 
<script>
import ClickOutside from "vue-click-outside";

export default {
  directives: {
    ClickOutside,
  },
  data: function () {
    return {
      isOpen: false,
      types: [
        {
          id: "instagram",
          name: "Instagram",
          icon: "/images/professionals/instagram.svg",
        },
        {
          id: "youtube",
          name: "Youtube",
          icon: "/images/professionals/youtube.svg",
        },
        {
          id: "twitter",
          name: "Twitter",
          icon: "/images/professionals/twitter.svg",
        },
      ],
      selectedType: [],
    };
  },
  methods: {
    handleToggle: function () {
      this.isOpen = !this.isOpen;
    },
    handleHide: function () {
      this.isOpen = false;
    },
    handleSelectType: function (type) {
      this.selectedType = type;
      this.isOpen = false;
    },
  },
  mounted: function () {
    this.selectedType = this.types[0];
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  background: #ffffff;
  border-radius: 16px;

  .select {
    cursor: pointer;
    border-bottom: 1px solid #f0f1f3;
    position: relative;

    .selected-value {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .arrow {
        right: 30px;
        position: absolute;
      }
    }

    .item {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 16px 30px;

      img {
        margin-right: 12px;
      }

      span {
        font-family: SF Pro Display;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
      }
    }

    ul {
      width: 100%;
      background: #fff;
      position: absolute;
      border-radius: 0 0 16px 16px;
      margin: 0;
      padding: 0;
      li {
        list-style: none;
      }
    }
  }

  .profile {
    display: flex;
    align-items: center;
    padding: 22px 27px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
    }

    & > div {
      display: flex;
      flex-direction: column;

      span {
        &:first-child {
          font-family: SF Pro Display;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          color: #a1a4b1;
          margin-bottom: 8px;
        }

        &:last-child {
          font-family: SF Pro Display;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: #000000;
        }
      }
    }
  }
}
</style>